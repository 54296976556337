<template>
  <nav class="navbar navbar-expand-lg shadow navbar-light fixed-top bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img
          src="@/assets/logo.webp"
          width="265"
          height="80"
          alt="KeynwynBooks_Logo"
        />
      </router-link>
      <!--Mobile View Toggle-->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <!--Mobile View Toggle-->

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item px-2">
            <router-link class="nav-link fs-6" to="/" @click="closeMenu"
              >HOME</router-link
            >
          </li>
          <li class="nav-item px-2">
            <router-link class="nav-link fs-6" to="/features" @click="closeMenu"
              >FEATURE</router-link
            >
          </li>

          <li class="nav-item px-2">
            <router-link
              class="nav-link fs-6 active"
              to="/maid-agency-software-pricing-singapore"
              @click="closeMenu"
              >PRICING</router-link
            >
          </li>
          <li class="nav-item px-2">
            <router-link
              class="nav-link fs-6"
              to="/promotions"
              @click="closeMenu"
              >PROMOTIONS</router-link
            >
          </li>

          <li class="nav-item px-2">
            <router-link class="nav-link fs-6" to="/blog" @click="closeMenu"
              >BLOG</router-link
            >
          </li>
          <li class="nav-item px-2">
            <!-- <div class="btn-group">
              <a
                class="nav-link fs-6"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                ACADEMY
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a class="dropdown-item" href="" target="_blank">
                    CERTIFICATION
                  </a>
                </li>
              </ul>
            </div> -->
          </li>
          <li class="nav-item px-2">
            <router-link
              class="nav-link fs-6"
              to="/become-a-partner"
              @click="closeMenu"
              >PARTNERS</router-link
            >
          </li>
          <li class="nav-item px-2">
            <router-link
              class="nav-link fs-6"
              to="/contact-us"
              @click="closeMenu"
              >CONTACT</router-link
            >
          </li>
        </ul>
        <div class="d-grid gap-1 d-md-flex justify-content-md-end">
          <a
            href="/demo"
            class="btn btn-success active"
            role="button"
            target="_blank"
            >BOOK DEMO</a
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    closeMenu() {
      if (window.innerWidth < 992) {
        document.querySelector(".navbar-collapse").classList.remove("show");
      }
    },
  },
};
</script>

<style scoped>
/* .img-logo {
  width: 210px;
} */

/* .router-link-active {
  font-weight: bold;
} */

.nav-link {
  color: rgb(30 41 59);
}
.router-link-active {
  color: rgb(0, 8, 0);
}
.nav-link:hover {
  color: rgb(75 93 214);
}

.navbar {
  padding-left: 20px;
  padding-right: 20px;
}

.nav-item {
  font-size: 14px;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 42%) !important;
}
</style>
